import React from 'react';

const Login = React.lazy(() => import('./App/pages/Login'));
const SetPassword = React.lazy(() => import('./App/pages/SetPassword'));

const route = [
    { path: '/login', exact: true, name: 'ログイン', component: Login },
    { path: '/set_password', exact: true, name: 'パスワード設定', component: SetPassword },
];

export default route;
