import axios from "axios";
import { downloadFile } from "../helpers/common";

let SERVER_BASE_URL = process.env.REACT_APP_API_URL;

const initHttp = (optionalHeaders) => {
    let headers = {
        'Content-Type': 'application/json',
        ...optionalHeaders,
    };
    const authToken = localStorage.getItem('authToken');
    if (authToken) headers['Authorization'] = `Bearer ${authToken}`;
    let http = axios.create({ headers });
    http.interceptors.response.use(undefined, (error) => {
        // If error is 401
        const authToken = localStorage.getItem('authToken');
        if (authToken && error.response.status === 401) {
            localStorage.removeItem('authToken');
            window.location.href = '/';
        }

        return Promise.reject(error);
    });

    return http;
};

const postRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .post(requestUrl, data, headers)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error.response?.data);
        });
};

const getRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .get(requestUrl, data)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
};

const putRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .put(requestUrl, data)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error.data);
        });
};

const patchRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .patch(requestUrl, data)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error.data);
        });
};

const deleteRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .delete(requestUrl, data)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error.data);
        });
};

const exportRequest = (path, data, resolve, reject) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp({});
    http
        .post(requestUrl, data)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error.response);
        });
};

const downloadRequest = (path, data, resolve, reject, type) => {
    const requestUrl = SERVER_BASE_URL + path;
    let headers = {'Accept': type};
    const authToken = localStorage.getItem('authToken');
    if (authToken) headers['Authorization'] = `Bearer ${authToken}`;
    let http = axios.create({ headers });
    const postData = {
        response_type: 'arraybuffer',
        file_name: data.file_name,
        file_path: data.file_path,
    };

    http
        .post(requestUrl, postData)
        .then((response) => {
            downloadFile(response, data.file_name);
            resolve(response);
        })
        .catch((error) => {
            reject(error.response);
        });
};

export default {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    exportRequest,
    downloadRequest,
};
